<template>
  <v-dialog v-model="open" scrollable width="700">
    <v-form v-model="valid" v-if="room" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="mode === 'create'">Raum erfassen</span>
          <span v-if="mode === 'edit'">Raum bearbeiten</span>
        </v-card-title>

        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                v-model="room.name"
                label="Name"
                type="text"
                :maxlength="255"
                :rules="textRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    mode: "create",
    valid: false,

    roomId: null,
    room: null,

    textRules: [v => !!v || "Muss ausgefüllt sein"],
    emailRules: [
      v => (!!v && /.+@.+\..+/.test(v)) || "E-Mail muss gültig sein"
    ],
    numericRules: [v => parseInt(v) > 0 || "Muss eine Nummer > 0 sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        if (this.mode === "create") {
          this.resetForm();
        } else if (this.mode === "edit") {
          this.fetchRoom();
        }
      }
    }
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.room = {
        id: null,
        name: null
      };
    },

    /**
     * Fetch customer from server
     */
    fetchRoom() {
      this.$api.http.get(`backoffice/rooms/${this.roomId}`).then(response => {
        this.room = response.data;
      });
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save room.
     */
    save() {
      if (this.mode === "create") {
        this.$api.http
          .post(`backoffice/rooms`, this.room)
          .then(response => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      } else if (this.mode === "edit") {
        this.$api.http
          .put(`backoffice/rooms/${this.roomId}`, this.room)
          .then(response => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      }
    }
  }
};
</script>
