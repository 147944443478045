<template>
  <div class="rooms-list mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Räume</h1>
        </v-col>
        <v-col class="text-sm-right">
          <tooltip-button
            icon="add"
            text="Raum erfassen"
            color="primary"
            @click="openCreateDialog()"
            classNames="ml-3"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/rooms'"
            :search-enabled="true"
            :headers="headers"
            ref="roomsDatatable"
          >
            <template v-slot:[`item.id`]="{ item }">
              <router-link :to="`/rooms/${item.id}`">
                {{ item.id }}
              </router-link>
            </template>
            <template v-slot:[`item.number`]="{ item }">
              <router-link :to="`/rooms/${item.id}`">
                {{ item.number }}
              </router-link>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <router-link :to="`/rooms/${item.id}`">
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:[`item.available_seatings`]="{ item }">
              {{ formatAvailableSeatings(item.available_seatings) }}
            </template>
            <template v-slot:[`item.is_bookable`]="{ item }">
              <v-icon v-if="item.is_bookable" color="success">check</v-icon>
              <v-icon v-else color="error">clear</v-icon>
            </template>
            <template v-slot:[`item.published_at`]="{ item }">
              <v-icon v-if="item.published_at" color="success">check</v-icon>
              <v-icon v-else color="error">clear</v-icon>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <tooltip-button
                icon="delete"
                text="Löschen"
                color="default"
                :dark="false"
                @click="openDeleteDialog(item.id, item.name)"
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <room-dialog
      ref="roomDialog"
      @saved="$refs.roomsDatatable.getDataFromApi()"
    />
    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.roomsDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TooltipButton from "@/components/forms/TooltipButton";
import RemoteDataTable from "@/components/RemoteDataTable";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import RoomDialog from "@/components/dialogs/RoomDialog";

export default {
  name: "rooms",

  components: {
    TooltipButton,
    RemoteDataTable,
    DeleteDialog,
    RoomDialog
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Nummer", align: "left", sortable: true, value: "number" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      {
        text: "Bestuhlungen",
        align: "left",
        sortable: true,
        value: "available_seatings"
      },
      { text: "Grösse (m2)", align: "left", sortable: true, value: "size" },
      { text: "Buchbar", align: "left", sortable: true, value: "is_bookable" },
      {
        text: "Publiziert",
        align: "left",
        sortable: true,
        value: "published_at"
      },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action",
        width: 150
      }
    ],
    search: null
  }),

  computed: {
    ...mapState("app", ["configs"])
  },

  methods: {
    ...mapMutations("app", [
      "setFullscreenDialogComponent",
      "setFullscreenDialogConfig"
    ]),

    /**
     * Format available seatings.
     */
    formatAvailableSeatings(availableSeatings) {
      return availableSeatings
        .map(seating => {
          let seatingName =
            this.configs.seating_types[seating.type] ?? "Unbekannt";
          return seatingName + ` (${seating.capacity})`;
        })
        .join(", ");
    },

    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.roomDialog.mode = "create";
      this.$refs.roomDialog.open = true;
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, email) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${email}`;
      this.$refs.deleteDialog.entityUrl = "rooms";
      this.$refs.deleteDialog.open = true;
    }
  }
};
</script>

<style lang="scss">
.rooms-list {
  a {
    text-decoration: none;
    color: var(--v-primary-darken1);
  }
}
</style>
