var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rooms-list mt-4 ml-8 mr-8"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"display-2 mb-5"},[_vm._v("Räume")])]),_c('v-col',{staticClass:"text-sm-right"},[_c('tooltip-button',{attrs:{"icon":"add","text":"Raum erfassen","color":"primary","classNames":"ml-3","position":"top"},on:{"click":function($event){return _vm.openCreateDialog()}}})],1)],1),_c('v-card',{staticClass:"elevation-0",attrs:{"color":"default","outlined":""}},[_c('v-card-text',[_c('remote-data-table',{ref:"roomsDatatable",attrs:{"url":'backoffice/rooms',"search-enabled":true,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/rooms/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/rooms/" + (item.id))}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/rooms/" + (item.id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.available_seatings",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAvailableSeatings(item.available_seatings))+" ")]}},{key:"item.is_bookable",fn:function(ref){
var item = ref.item;
return [(item.is_bookable)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("clear")])]}},{key:"item.published_at",fn:function(ref){
var item = ref.item;
return [(item.published_at)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("clear")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"icon":"delete","text":"Löschen","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.openDeleteDialog(item.id, item.name)}}})]}}],null,true)})],1)],1)],1),_c('room-dialog',{ref:"roomDialog",on:{"saved":function($event){return _vm.$refs.roomsDatatable.getDataFromApi()}}}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":function($event){return _vm.$refs.roomsDatatable.getDataFromApi()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }